<template>
  <engaging-stand-out-block class="cta-block" :title="title">
    <mcr-button-router-link-to-pr slot="button" label="Get Expert Advice"></mcr-button-router-link-to-pr>
    <div class="checkmark-list">
      <div class="checkmark-item">Over 250+ families connected</div>
      <div class="checkmark-item">Free consultation and quote</div>
      <div class="checkmark-item">Opt out at any time</div>
    </div>
  </engaging-stand-out-block>
</template>
<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import EngagingStandOutBlock from '@common/elements/layouts/EngagingStandOutBlock';

export default {
  data() {
    return {
      title: 'Exploring your family history? Get help from our China-based researchers.',
    };
  },
  name: 'get-help-cta',
  components: {EngagingStandOutBlock, McrButtonRouterLinkToPr},
};
</script>
<style lang="scss" scoped>
.cta-block {
  margin: 60px 0 0;
  clear: both;

  .checkmark-list {
    color: $neutral-500;
  }
}
</style>
