<template>
  <div class="wiki-events-page">
    <div class="limited_content" v-if="wikiEventsLoadingState">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="limited_content" v-else>
      <h4>Events</h4>
      <div class="heading-1">Upcoming Events in {{ currentYear }}</div>
      <div>
        <div class="heading-3">Weekly</div>
        <div class="heading-6">My China Roots Community Calls</div>
        <p>🗓 Every Monday 7:00 pm PDT / Tuesday 10:00 am GMT+8</p>
        <p>
          🌏 Get peer support in your genealogy research and share your discoveries with ancestral detectives around the
          world
        </p>
        <p>
          🔗
          <router-link :to="{name: 'community'}" class="bold"
            >Join the My China Roots community on Discord (it's free!)</router-link
          >
        </p>
      </div>
      <div v-for="events in upcomingEvents">
        <div class="heading-3">{{ events[0].date_month_name }}</div>
        <div v-for="event in events" class="event-data">
          <div class="heading-6">{{ event.title }}</div>
          <p class="bold" v-if="event.subtitle">{{ event.subtitle }}</p>
          <p>
            <span>🗓 {{ event.date }}</span
            ><span v-if="event.date_end"> - {{ event.date_end }}</span
            ><span> | 🗣 {{ event.speaker }}</span
            ><span v-if="event.register_link">
              | 🔗 <a :href="event.register_link" class="bold">Register for Free</a></span
            ><span v-else> | 🔗 Coming Soon</span>
          </p>
        </div>
      </div>
      <hr />
      <div class="heading-1">Past Events</div>
      <div v-for="year in pastYears" class="past-year">
        <div class="heading-2">{{ year }}</div>
        <div v-for="event in pastEventsByYear[year]" class="event-data">
          <div class="heading-6">{{ event.title }}</div>
          <p class="bold" v-if="event.subtitle">{{ event.subtitle }}</p>
          <p>
            <span>🗓 {{ event.date }}</span
            ><span v-if="event.date_end"> - {{ event.date_end }}</span
            ><span> | 🗣 {{ event.speaker }}</span
            ><span v-if="event.replay_link"> | 🔗 <a :href="event.replay_link" class="bold">Watch the Replay</a></span>
          </p>
          <p v-if="event.youtube_embed_link" class="embeded-video">
            <lazy-youtube-video
              :src="event.youtube_embed_link"
              class="main-video"
              preview-image-size="hqdefault"
            ></lazy-youtube-video>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Events',
    };
  },
  created() {
    this.$store.dispatch('fetchWikiEventsAction');
  },
  computed: {
    ...mapGetters(['wikiEventsState', 'wikiEventsLoadingState']),
    currentYear() {
      return new Date().getFullYear();
    },
    pastEventsByYear() {
      const today = new Date();
      const byYear = {};
      this.wikiEventsState
        .filter(event => event.date && new Date(event.date) <= today)
        .forEach(event => {
          if (event.date_year) {
            byYear[event.date_year] = byYear[event.date_year] || [];
            byYear[event.date_year].push(event);
          }
        });
      return byYear;
    },
    upcomingEvents() {
      const today = new Date();
      const events = this.wikiEventsState.filter(event => event.date && new Date(event.date) > today);
      return this.splitEventsByMonthName(sortBy(events, o => o.date && new Date(o.date), 'desc'));
    },
    pastYears() {
      return new Set(this.wikiEventsState.map(event => event.date_year.toString()));
    },
  },
  methods: {
    splitEventsByMonthName(events) {
      const byMonth = {};
      events.forEach(event => {
        if (event.date_month_name) {
          byMonth[event.date_month_name] = byMonth[event.date_month_name] || [];
          byMonth[event.date_month_name].push(event);
        }
      });
      return Object.values(byMonth);
    },
  },
  components: {LazyYoutubeVideo},
};
</script>

<style lang="scss" scoped>
.wiki-events-page {
  .heading-1 {
    margin-top: 20px;
    margin-bottom: 35px;
  }
  .heading-2 {
    margin-bottom: 24px;
  }
  .heading-3 {
    margin-top: 35px;
  }
  .heading-3 {
    margin-bottom: 24px;
  }
  .heading-6 {
    margin-top: 0;
  }
  .past-year {
    margin-top: 35px;
  }

  hr {
    margin: 40px 0;
    border-color: $neutral-300;
  }

  .event-data + .event-data {
    border-top: 1px solid $neutral-300;
    margin-top: 20px;
    padding-top: 20px;
  }

  @media only screen and (min-width: $main-menu-breakpoint) {
    .embeded-video {
      width: 500px;
    }
  }
}
</style>
